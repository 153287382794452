<template>
  <Modal v-model="modalShow" width="800" title="采购资源档期平移" :footer-hide="true">
    <Card :bordered="false" class="remark m-b-10">
      <p>tips：* 已出合同的档期平移会自动平移合同档期</p>
      <p style="padding-left: 31px;">* 合同审批中不允许档期平移</p>
      <p style="padding-left: 31px;">* 已提交上刊需求不允许档期平移</p>
    </Card>

    <Row class="p-b-5">
      <i-col span="24">
        <span class="title m-r-10">媒体类别</span>
        <div v-for="(item,i) in categories" :key="i" @click="handleChangeCategory(item.categoryId)"
          :class="checkCategory === item.categoryId?'workplatform-radio-button-active':'workplatform-radio-button'">{{item.categoryName}}</div>
      </i-col>
    </Row>

    <Row class="p-b-5">
        <span class="title m-r-10 m-t-2">采购档期</span>
        <div v-for="(schedule,i) in schedules" :key="i" @click="handleChangeSchedule(i)"
          :class="scheduleIndex === i?'workplatform-radio-button-active':'workplatform-radio-button'">{{formatSchedule(schedule.startDate, schedule.endDate)}}</div>
    </Row>

    <Row class="p-b-5">
      <i-col span="24">
          <span class="title m-r-10">采购产品</span>
          <div v-for="(product,i) in products" :key="i" @click="handleChangeProduct(i)"
            :class="productsIndex === i?'workplatform-radio-button-active':'workplatform-radio-button'">{{product.productName}} {{product.priceType===1?'':'特价'}} </div>
      </i-col>
    </Row>

    <Row class="p-b-5">
        <i-col span="24">
          <span class="title m-r-10">生效档期</span>
          <span>{{ changeBean.newStartDate }} 至 {{ changeBean.newEndDate }}</span>
        </i-col>
    </Row>
    <Row class="p-b-5">
        <i-col span="24">
            <span class="title m-r-10">平移天数</span>
            <InputNumber size="small" v-model="changeDay" controls-outside style="width: 100px;" @on-change="handleChangeDay"></InputNumber>
            <Button class="m-l-10" type="success" size="small" @click="handleValidChange">检测点位冲突</Button>
        </i-col>
    </Row>

    <div class="text-center m-b-20 m-t-10">
      <template v-if="validMove===1">
        <Icon type="md-close" size="100" color="#ed4014" />
        <p class="remark">当前平移档期会导致点位冲突，请联系管理员调整对应点位信息后重新检测！</p>
        <p class="m-t-10">
          冲突点位：<span v-for="(error,i) in errorData" :key="i">{{ formatErrorMsg(error, i) }}</span>
        </p>
      </template>

      <template v-else-if="validMove===2">
        <Icon type="md-checkmark" size="100" color="#19be6b" />
        <p class="remark">未发现点位冲突，您现在可以放心的点位平移了！</p>
        <p class="m-t-10 m-b-20">
          <Button type="warning" @click="hanldeBeginMove">开始档期平移</Button>
        </p>
      </template>

      <template v-else>
        <Icon type="md-alert" size="100" color="#515a6e" />
        <p class="remark">请设置媒体产品的采购平移天数，并点击“检测点位冲突”预审验证。</p>
      </template>
    </div>

    <Spin fix size="large" v-if="spinShow">
        <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
        <div>数据变更中...</div>
    </Spin>
  </Modal>
</template>

<script>
import { GetCurrentSchedule, GetDateStr } from '@/utils/dateFormat'
import { getProductPrice } from '@/api/order/orderprice'
import { checkMoveProductSchedule, moveProductSchedule } from '@/api/order/orderitem'

export default {
  data () {
    return {
      modalShow: false,
      spinShow: false,
      purchaseList: [], // 采购清单

      categories: [], // 媒体类别
      checkCategory: null,
      schedules: [],
      scheduleIndex: 0,
      products: [], // 采购产品
      productsIndex: 0,

      changeBean: {
        newEndDate: '',
        newStartDate: '',
        oldEndDate: '',
        oldStartDate: '',
        orderId: null,
        productId: null
      },

      changeDay: 0,
      validMove: 0,
      errorData: []
    }
  },
  methods: {
    showModal (orderId) {
      this.modalShow = true
      this.changeBean.orderId = orderId
      this.categories = []

      getProductPrice({ orderId: orderId }).then(res => {
        // 格式化媒体类型和档期, 切套装不能平移
        this.purchaseList = res.filter(x => x.categoryId !== 7)

        this.purchaseList.forEach(element => {
          if (!this.categories.find(x => x.categoryId === element.categoryId)) {
            this.categories.push({
              categoryId: element.categoryId,
              categoryName: element.categoryName
            })
          }
        })

        if (this.categories.length > 0) {
          this.handleChangeCategory(this.categories[0].categoryId)
        }
      })
    },
    handleChangeCategory (categoryId) {
      this.checkCategory = categoryId
      this.schedules = []
      const productArrayShedule = this.purchaseList.filter(x => x.categoryId === this.checkCategory)

      productArrayShedule.forEach(element => {
        if (!this.schedules.find(x => x.startDate === element.startDate && x.endDate === element.endDate)) {
          this.schedules.push({
            startDate: element.startDate,
            endDate: element.endDate
          })
        }
      })

      this.handleChangeSchedule(0)
    },
    handleChangeSchedule (index) {
      this.scheduleIndex = index
      this.products = []
      const productArray = this.purchaseList.filter(x => x.categoryId === this.checkCategory && x.startDate === this.schedules[index].startDate && x.endDate === this.schedules[index].endDate)

      productArray.forEach(element => {
        if (!this.products.find(x => x.productId === element.productId)) {
          this.products.push({
            productId: element.productId,
            productName: element.productName,
            priceType: element.priceType
          })
        }
      })

      this.changeBean.oldStartDate = this.schedules[index].startDate
      this.changeBean.oldEndDate = this.schedules[index].endDate
      // 初始化产品
      this.handleChangeProduct(0)
    },
    handleChangeProduct (index) {
      this.productsIndex = index
      this.changeBean.productId = this.products[index].productId
      this.changeDay = 0

      this.handleChangeDay()
    },
    formatSchedule (start, end) {
      return GetCurrentSchedule(start, end)
    },
    formatErrorMsg (error, index) {
      let msg = error
      if (index < this.errorData.length - 1) {
        msg += '，'
      }

      return msg
    },
    handleChangeDay () {
      this.changeBean.newStartDate = GetDateStr(this.changeBean.oldStartDate, this.changeDay + 1)
      this.changeBean.newEndDate = GetDateStr(this.changeBean.oldEndDate, this.changeDay + 1)

      this.validMove = 0
    },
    handleValidChange () {
      checkMoveProductSchedule(this.changeBean).then(res => {
        this.errorData = res.data
        this.validMove = (res.data.length > 0) ? 1 : 2
      })
    },
    hanldeBeginMove () {
      // 仅验证通过，才允许提交平移
      if (this.validMove === 2) {
        this.spinShow = true

        moveProductSchedule(this.changeBean).then(res => {
          if (res.success) {
            // 更新订单详情
            this.$emit('update-detail')
            // 关闭当前页面
            this.$Notice.success({ desc: '订单档期平移完毕！', title: '操作成功' })
            this.modalShow = false
          }

          this.spinShow = false
        }).catch(() => {
          this.spinShow = false
        })
      }
    }
  }
}
</script>
