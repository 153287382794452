<template>
  <Modal v-model="ModalShow" width="1000" title="订单冲突详情" :footer-hide="true">
    <Table stripe :columns="columns" :data="tableData"></Table>

    <Divider>冲突预测（有其他订单已采购当前资源，并已出合同）</Divider>

    <Table stripe :columns="columns" :data="tableData2"></Table>
  </Modal>
</template>

<script>
import { getConflictInfo, getConflictPredictList } from '@/api/order/order'
import { ParseDate } from '@/utils/dateFormat'

export default {
  props: {
    orderId: {
      type: [Number, String],
      default: ''
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      ModalShow: false,
      tableData: [],
      tableData2: [],
      columns: [
        { title: '当前资源编号', key: 'currentResourceCode' },
        { title: '当前产品名称', key: 'currentProductName' },
        {
          title: '当前档期',
          render: (h, params) => {
            return h('span', ParseDate(params.row.currentStartDate) + ' 至 ' + ParseDate(params.row.currentEndDate))
          }
        },
        { title: '冲突的广告主', key: 'advertiserName', className: 'demo-table-info-column' },
        { title: '冲突的品牌', key: 'brandName', className: 'demo-table-info-column' },
        { title: '冲突的销售', key: 'userName', className: 'demo-table-info-column' },
        {
          title: '冲突档期',
          className: 'demo-table-info-column',
          render: (h, params) => {
            return h('span', ParseDate(params.row.startDate) + ' 至 ' + ParseDate(params.row.endDate))
          }
        },
        { title: '冲突描述', key: 'desc', className: 'demo-table-info-column' }
      ]
    }
  },
  methods: {

  },
  watch: {
    ModalShow (val) {
      this.$emit('update:isShow', val)
    },
    isShow (val) {
      this.ModalShow = val
      if (val) {
        const query = {
          orderId: this.orderId
        }

        getConflictInfo(query).then(res => {
          this.tableData = res
        })

        getConflictPredictList(query).then(res => {
          this.tableData2 = res
        })
      }
    }
  }
}
</script>
