<template>
  <div>
    <h3 class="text-center">采购服务（服务费总金额：{{ formatMoney(amountServicePrice) }}元）</h3>
    <Row class="m-t-10">
      <i-col span="24">
        <span class="title">已采购媒体：</span>
        <RadioGroup size="small" v-model="chooseProductIndex" type="button" @on-change="handleChangeProduct">
          <Radio v-for="(item, index) in allPurchaseProducts" :label="index" :key="index">{{ item.productName }}
            {{ formatSchedule(item.startDate, item.endDate) }}</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Divider style="margin: 10px 0 0 0;" />
    <div v-for="(item, index) in resourcetypeServiceitemList" :key="index">
      <p class="p-t-20">{{ item.productskuInfo.productskuName }}服务 (数量：{{ item.productskuInfo.quantity }})</p>
      <Row class="p-t-5">
        <i-col span="8" v-for="(serviceItem, index2) in formatServiceItems(item)" :key="index2">
          <Button size="small" type="primary" class="m-r-5" :ghost="!serviceItem.isChecked"
            @click="handleCheckServiceItem(serviceItem)">{{ serviceItem.typeName }} - {{ serviceItem.name }} |
            {{ formatMoney(serviceItem.price) }}/{{ serviceItem.unitName }}</Button>
          <template v-if="serviceItem.isChecked">
            <InputNumber size="small" style="width:50px" :min="0" v-model="serviceItem.quantity" />
            {{ serviceItem.unitName }}
          </template>
        </i-col>
      </Row>

      <p v-if="formatServiceItems(item).length < 1" class="p-t-5 p-l-10 remark">暂无服务数据</p>
    </div>

    <p class="p-t-20 text-right">{{ chooseDeviceName }} 所选服务费小计：<span
        class="money">{{ formatMoney(amountServiceGoodPrice) }}元</span></p>

    <div class="text-right p-t-10">
      <Button type="success" size="small" :loading="loadingSubmitPurchase" @click="handleSubmitPurchase">
        <span v-if="!loadingSubmitPurchase">确定采购</span>
        <span v-else>加载中...</span>
      </Button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'
import { getItemList } from '@/api/order/orderserviceV2'

export default {
  data () {
    return {
      chooseProductIndex: 0, // 当前选中的产品

      loadingSubmitPurchase: false,
      orderId: null,

      allOrderServiceItemlist: [], // 获取全局的服务采购项清单
      allPurchaseProducts: [], // 已采购的媒体清单

      resourcetypeServiceitemList: [],
      serviceitemList: []
    }
  },
  methods: {
    showModal (orderId) {
      this.serviceitemList = []
      this.chooseProductIndex = 0
      this.orderId = orderId

      getItemList({ orderId: orderId }).then(res => {
        this.allOrderServiceItemlist = res
        this.allPurchaseProducts = res.map(x => x.productInfo)
        if (this.allPurchaseProducts.length > 0) {
          this.handleChangeProduct()
        }
      })
    },
    formatSchedule (startDate, endDate) {
      return GetCurrentSchedule(startDate, endDate)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    formatServiceItems (resourcetypeServiceitem) {
      return this.serviceitemList.filter(x => x.startDate === resourcetypeServiceitem.productskuInfo.startDate &&
        x.endDate === resourcetypeServiceitem.productskuInfo.endDate &&
        x.productskuId === resourcetypeServiceitem.productskuInfo.productskuId)
    },
    handleChangeProduct () {
      this.resourcetypeServiceitemList = this.allOrderServiceItemlist[this.chooseProductIndex].productskuServiceitemList

      this.resourcetypeServiceitemList.forEach(element => {
        // 循环当前产品下所有的服务
        element.serviceitemList.forEach(serviceItem => {
          if (!this.serviceitemList.find(x => x.startDate === element.productskuInfo.startDate &&
            x.endDate === element.productskuInfo.endDate &&
            x.productskuId === element.productskuInfo.productskuId && x.serviceitemId === serviceItem.serviceitemId)) {
            this.serviceitemList.push(Object.assign({
              endDate: element.productskuInfo.endDate,
              productskuId: element.productskuInfo.productskuId,
              quantity: element.productskuInfo.quantity,
              resourcetypeId: element.productskuInfo.resourcetypeId,
              startDate: element.productskuInfo.startDate,
              type: serviceItem.type,
              serviceitemId: serviceItem.serviceitemId,
              isChecked: false
            }, serviceItem))
          }
        })
      })
    },
    handleCheckServiceItem (checkItem) {
      checkItem.isChecked = !checkItem.isChecked
    },
    handleSubmitPurchase () {
      const submitData = {
        orderId: this.orderId,
        serviceitemList: this.serviceitemList.filter(x => x.isChecked)
      }

      // 获取站点数据汇总
      axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-order/v2/orderservice/additems',
        submitData,
        {
          headers: {
            'Content-Type': 'application/json',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true
        }
      ).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '新增成本项采购成功！' })
          // 清空当前表单数据
          this.serviceitemList = []
          this.chooseProductIndex = 0
          this.handleChangeProduct()

          // 切换至列表，并刷新
          this.$emit('on-success')
        } else {
          this.$Notice.error({ desc: res.errmsg })
        }
      })
    }
  },
  computed: {
    chooseDeviceName () {
      return this.allPurchaseProducts.length > 0 ? this.allPurchaseProducts[this.chooseProductIndex].productName : ''
    },
    amountServicePrice () {
      const checkedItems = this.serviceitemList.filter(x => x.isChecked)
      let amount = 0
      checkedItems.forEach(element => {
        amount += (element.price * element.quantity)
      })

      return amount
    },
    amountServiceGoodPrice () {
      const chooseProduct = this.allPurchaseProducts[this.chooseProductIndex]
      const checkedItems = this.serviceitemList.filter(x => x.startDate === chooseProduct.startDate &&
        x.endDate === chooseProduct.endDate &&
        x.productskuId === chooseProduct.productskuId &&
        x.isChecked)

      let amount = 0
      checkedItems.forEach(element => {
        amount += (element.price * element.quantity)
      })

      return amount
    }
  }
}
</script>
