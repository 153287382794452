<template>
  <Modal v-model="modalPurchaseService" width="1200" footer-hide>
    <Tabs v-model="chooseTab">
        <TabPane label="已购清单" name="tab1">
          <Row class="m-t-10 m-b-10">
            <i-col span="24">
              <span class="title">查询档期：</span>
              <RadioGroup size="small" v-model="scheduleIndex" type="button" @on-change="handleChangeSchedule">
                <Radio :label="-1">全部</Radio>
                <Radio
                  v-for="(item,index) in schedules"
                  :label="index"
                  :key="index"
                >{{formatSchedule(item.startDate,item.endDate)}}</Radio>
              </RadioGroup>
            </i-col>
          </Row>
          <Table size="small" :columns="purchaseColumns" :data="showPurchaseData" stripe  show-summary :summary-method="handleSummary" height="400"></Table>
        </TabPane>
        <TabPane label="新增采购" name="tab2">
          <purchaseServiceNew ref="purchaseServiceNew" @on-success="handleSuccessSubmit"/>
        </TabPane>
    </Tabs>
  </Modal>
</template>

<script>
import purchaseServiceNew from './PurchaseServiceNew2'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'
import { getPurchasedItemList, deleteItem } from '@/api/order/orderserviceV2'

export default {
  components: {
    purchaseServiceNew
  },
  data () {
    return {
      modalPurchaseService: false,
      chooseTab: 'tab1',
      orderId: null,
      purchaseColumns: [
        {
          title: '档期',
          width: 200,
          render: (h, params) => {
            return h('span', GetCurrentSchedule(params.row.startDate, params.row.endDate, false))
          }
        },
        { title: '产品', key: 'productName' },
        {
          title: '服务内容',
          render: (h, data) => {
            return h('span', data.row.typeName + ' - ' + data.row.name)
          }
        },
        {
          title: '采购数量',
          render: (h, data) => {
            return h('span', data.row.quantity + ' ' + data.row.unitName)
          }
        },
        {
          title: '单价',
          align: 'right',
          key: 'price',
          render: (h, data) => {
            return h('span', {
              attrs: {
                style: 'color:#44bd32'
              }
            }, toMoney(data.row.price))
          }
        },
        {
          title: '金额小计',
          align: 'right',
          key: 'totalPrice',
          render: (h, data) => {
            return h('span', {
              attrs: {
                style: 'color:#44bd32'
              }
            }, toMoney(data.row.totalPrice))
          }
        },
        { title: '提交时间', width: 150, key: 'createTime' },
        {
          title: '操作',
          render: (h, data) => {
            return h('a', {
              on: {
                click: () => {
                  this.handleDeleteItem(data.row.id)
                }
              }
            }, '删除')
          }
        }
      ],
      purchaseData: [],
      showPurchaseData: [],
      schedules: [],
      scheduleIndex: -1
    }
  },
  methods: {
    showModal (orderId) {
      this.orderId = orderId
      this.modalPurchaseService = true
      this.$refs.purchaseServiceNew.showModal(orderId)
      this.loadPurchaseItemList()
    },
    loadPurchaseItemList () {
      getPurchasedItemList({ orderId: this.orderId }).then(res => {
        this.purchaseData = res

        const scheduleMap = new Map()
        res.forEach(item => {
          const schedule = scheduleMap.get(item.startDate + '~' + item.endDate)
          if (!schedule) {
            scheduleMap.set(item.startDate + '~' + item.endDate, item)
          }
        })
        this.schedules = [...scheduleMap.values()]
        this.scheduleIndex = -1
        this.handleChangeSchedule()
      })
    },
    formatSchedule (startDate, endDate) {
      return GetCurrentSchedule(startDate, endDate)
    },
    handleChangeSchedule () {
      if (this.scheduleIndex === -1) {
        this.showPurchaseData = this.purchaseData
      } else {
        const currentSchedule = this.schedules[this.scheduleIndex]
        this.showPurchaseData = this.purchaseData.filter(x => x.startDate === currentSchedule.startDate && x.endDate === currentSchedule.endDate)
      }
    },
    handleSuccessSubmit () {
      this.chooseTab = 'tab1'
      this.loadPurchaseItemList()

      // 更新订单详情
      this.$emit('update-service')
    },
    handleSummary ({ columns, data }) {
      const needSummarykey = [
        'quantity',
        'totalPrice'
      ]
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '总计'
          }
          return
        }

        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: key === 'totalPrice' ? toMoney(v) : v
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    handleDeleteItem (seriveItemId) {
      this.$Modal.confirm({
        title: '确定删除？',
        content: '<p>确定要删除该条数据，删除后将不可恢复！</p>',
        loading: true,
        onOk: () => {
          deleteItem({ id: seriveItemId }).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '删除成本项成功' })
              this.loadPurchaseItemList()
              // 更新订单详情
              this.$emit('update-service')
            }
            this.$Modal.remove()
          })
        }
      })
    }
  }
}
</script>
