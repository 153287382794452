<template>
  <Modal v-model="ModalShow" width="1000" title="订单上刊尺寸表" :footer-hide="true">
    <div style="padding: 5px 0;">
      <template v-for="(size,index) in sizeList">
        <Button v-if="currentScheduleIndex == index" size="small" class="m-r-5" :key="index" @click="switchCurrentScheduleIndex(index)"
          type="primary" >{{getScheduleInfo(size.startDate,size.endDate)}}</Button>
        <Button v-else size="small" class="m-r-5 btn-black" :key="index" @click="switchCurrentScheduleIndex(index)"
          >{{getScheduleInfo(size.startDate,size.endDate)}}</Button>
      </template>
    </div>
    <Table :data="tableData" :columns="columns" height="450" size="small" stripe></Table>
  </Modal>
</template>

<script>
import { getResourceTypeQuantity } from '@/api/order/productdetail'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  props: {
    orderId: {
      type: [Number, String],
      default: ''
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      ModalShow: false,
      sizeList: [],
      currentScheduleIndex: 0,
      tableData: [],
      columns: [
        { title: '媒体类型', key: 'resourcetypeName' },
        {
          title: '制作尺寸(长*高)',
          align: 'center',
          children: [
            {
              title: '(单位：mm)',
              render: (h, params) => {
                return h('div', [
                  h('span', { style: { 'font-weight': 'bold' } }, params.row.makewidth),
                  h('span', '*'),
                  h('span', { style: { 'font-weight': 'bold' } }, params.row.makeheight)
                ])
              }
            }
          ]
        },
        {
          title: '见光尺寸(长*高)',
          align: 'center',
          children: [
            {
              title: '(单位：mm)',
              render: (h, params) => {
                return h('div', [
                  h('span', { style: { 'font-weight': 'bold' } }, params.row.finalwidth),
                  h('span', '*'),
                  h('span', { style: { 'font-weight': 'bold' } }, params.row.finalheight)
                ])
              }
            }
          ]
        },
        { title: '资源数量', align: 'center', width: 80, key: 'quantity' },
        {
          title: '制作原件',
          align: 'center',
          children: [
            {
              title: '文件分辨率(dpi)',
              align: 'center',
              render: (h, params) => {
                return h('span', '72')
              }
            },
            {
              title: '输出分辨率(dpi)',
              align: 'center',
              render: (h, params) => {
                return h('span', '1220')
              }
            },
            {
              title: '格式',
              align: 'center',
              render: (h, params) => {
                return h('span', 'PSD、AI')
              }
            }
          ]
        }
      ]
    }
  },
  methods: {
    getScheduleInfo (startDate, endDate) {
      return GetCurrentSchedule(startDate, endDate)
    },
    switchCurrentScheduleIndex (index) {
      this.currentScheduleIndex = index
      this.tableData = this.sizeList[index].resourcetypeQuantityBeanList
    }
  },
  watch: {
    ModalShow (val) {
      this.$emit('update:isShow', val)
    },
    isShow (val) {
      this.ModalShow = val
      if (val) {
        const query = {
          orderId: this.orderId
        }
        getResourceTypeQuantity(query).then(res => {
          this.sizeList = res
          if (res.length > 0) {
            this.tableData = res[0].resourcetypeQuantityBeanList
          }
        })
      }
    }
  }
}
</script>
