<template>
   <div style="min-height:300px">
    <!-- <Row class="p-b-10">
      <i-col span="24">
        <label class="title">计算方式</label>
        <RadioGroup v-model="mode">
          <Radio :label="1">按订单总折扣计算</Radio>
          <Radio :label="2">自定义每项媒体价格</Radio>
        </RadioGroup>
      </i-col>
    </Row> -->
    <Row v-show="mode===1" class="p-b-10">
      <i-col span="12">
        <label class="title">合计折扣</label>
        <InputNumber size="small" v-model="discount" :max="10"></InputNumber>
        <span class="m-l-5">(折)</span>
      </i-col>
      <i-col span="12" class="text-right remark">
        注：默认结算折扣为 <span class="text-orange">{{defaultDiscount}}</span>折
      </i-col>
    </Row>

    <h3 class="p-t-10">媒体发布费结算清单</h3>
    <div>
        <OrdeBasicrDetail  v-if="mode===2" :products="products" :model='3'  @on-computed="handleComputed"  />
        <OrdeBasicrDetail v-else :products="products" :model='2' :discount="discount" @on-computed="handleComputed"  />
    </div>
    <!-- <Row class="p-t-5">
      <i-col span="12">合计：</i-col>
      <i-col span="12" class="text-right">{{formatMoney(summaryPrice.totalUsePrice)}}</i-col>
    </Row> -->
    <Row class="p-t-5">
      <!-- <i-col span="12">
        <label class="title">综合折扣</label>
        <span class="text-red">{{ realDiscount}}折</span>
      </i-col> -->
      <i-col span="24" class="text-right p-r-10">
        刊例价总计：
        <!-- <span>{{formatMoney(total) }}</span> -->
        <span>{{formatMoney(summaryPrice.totalUsePrice) }}</span>
      </i-col>
    </Row>
    <hr />

    <h3 class="p-t-10">其他费用</h3>
    <Row>
        <i-col span="24" class="right">
            <Table stripe size="small" style="width:400px;float: inherit;" :columns="serviceColumns" :data="serviceData" border></Table>
        </i-col>
    </Row>
    <Row class="p-t-5">
        <i-col span="24" class="text-right">
          <!-- 合计：{{formatMoney(discountSumarPrice.otherPrice)}} -->
          合计：{{formatMoney(summaryPrice.finalUsePrice-summaryPrice.totalUsePrice)}}
        </i-col>
    </Row>
    <hr />
    <Row>
      <i-col span="12">
        <span class="remark">PS：最终价格以实际上刊设置为准</span>
      </i-col>
      <i-col span="12" class="text-right text-16">
        媒体发布费总计：：<b class="text-orange">{{formatMoney(discountSumarPrice.finalUsePrice)}}</b>
      </i-col>
    </Row>

  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import OrdeBasicrDetail from './OrdeBasicrDetail'

export default {
  props: {
    products: {
      type: Array,
      required: true
    },
    summaryPrice: {
      type: Object,
      required: true
    },
    defaultDiscount: {
      type: String,
      required: true
    }
  },
  components: {
    OrdeBasicrDetail
  },
  data () {
    return {
      mode: 1,
      discount: 10,
      realDiscount: 10,
      categories: [],
      total: 0,
      serviceColumns: [
        {
          title: '明细类别',
          width: 180,
          render: (h, params) => {
            return h('span', params.row.productName + ' - ' + params.row.ruleTypeName)
          }
        },
        { title: '刊例折扣', align: 'center', key: 'value' },
        {
          title: '费用',
          width: 130,
          align: 'right',
          render: (h, params) => {
            return h('span', this.formatMoney(params.row.usePrice))
          }
        }
      ],
      serviceData: [],
      discountSumarPrice: {} // 计算后的合计总价
    }
  },
  mounted () {
    this.discount = parseFloat(this.defaultDiscount)
    this.realDiscount = this.discount
    this.initDiscountServicePrice()
  },
  methods: {
    initDiscountServicePrice () {
      this.discountSumarPrice = {
        finalUsePrice: (this.summaryPrice.finalUsePrice * this.discount / 10).toFixed(2) * 1,
        otherPrice: ((this.summaryPrice.finalUsePrice - this.summaryPrice.totalUsePrice) * this.discount / 10).toFixed(2) * 1
      }

      if (this.summaryPrice.rulePriceResultList) {
        this.serviceData = this.summaryPrice.rulePriceResultList.map(res => {
          res.discountUserPrice = (res.usePrice * this.discount / 10).toFixed(2) * 1
          return res
        })
      }
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handleComputed (data) {
      this.realDiscount = (data.settlementPrice / data.usePrice * 10).toFixed(2)
      this.total = data.settlementPrice
      if (data.usePrice === 0) {
        this.realDiscount = 0.0
      }
    }

  },
  watch: {
    mode: function () {
      this.realDiscount = 0
      this.total = 0
    },
    discount: function () {
      this.realDiscount = 0
      this.total = 0
      this.initDiscountServicePrice()
    }
  }

}
</script>
